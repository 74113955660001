<template>
  <v-timeline :dense="$vuetify.breakpoint.smAndDown">
    <v-timeline-item
      v-for="item in events"
      :key="item.id"
      :icon="'mdi-check'"
      fill-dot
      class="mb-4 text-center"
      right
      small
    >
      <template v-slot:opposite>
        <p class="mb-0 pb-0">{{ displayUser(item.created_by_id) }}</p>
        <span>{{ item.date }}</span>
      </template>
      <v-card elevation="1" outlined>
        <v-card-text>
          <span class="text-h6">
            {{
              item.label
                ? item.label
                : item.single_line
                ? item.new_value
                : item.field
            }}
          </span>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <p class="pb-0 mb-0">{{ displayUser(item.created_by_id) }}</p>
            <p class="text-caption pb-0 mb-0">
              {{ item.date }}
            </p>
          </template>
        </v-card-text>
        <template v-if="!item.single_line">
          <v-card-text class="text--primary">
            {{ item.new_value }}
          </v-card-text>
          <v-card-text class="text-center">
            <v-icon>mdi-arrow-up</v-icon>
          </v-card-text>
          <v-card-text class="text--primary">
            {{ item.old_value }}
          </v-card-text>
        </template>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { sortBy, uniq } from "lodash-es";
import { computed, watch } from "vue-demi";
import { useMembers } from "../store/members.pinia";

export default {
  name: "AuditLogTimeline",
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  setup(props, { root }) {
    const membersStore = useMembers();

    const memberIds = computed(() => {
      return uniq(props.logs.map((log) => log.created_by_id)).filter((i) => i);
    });

    const members = computed(() => {
      return (
        membersStore.findInStore({
          query: {
            id: { $in: memberIds.value },
            $limit: 1000,
          },
        }) ?? []
      );
    });

    watch(
      () => memberIds.value,
      (newVal) => {
        if (newVal.length > 0) {
          newVal.map((id) => {
            // Fetch individually to prevent population issues on missing members
            return membersStore
              .get(id, {
                skipRequestIfExists: true,
              })
              .catch(() => console.warn(`Error fetching member ID: ${id}`));
          });
        }
      },
      { immediate: true }
    );

    const displayUser = (userId) => {
      if (typeof userId !== "string") return "";
      const user =
        members.value?.data?.find((user) => user.id === userId) ?? {};
      return (user.displayName ?? "Legacy User").trim();
    };

    const formatters = {
      string: (value) => value,
      boolean: (value) => ([1, "1", true].includes(value) ? "True" : "False"),
    };

    const events = computed(() => {
      return sortBy(
        props.logs.map((log) => {
          const formatter =
            log.field_type && formatters[log.field_type]
              ? log.field_type
              : "string";
          return {
            label: log.label ?? false,
            id: log?.id,
            field: log?.field,
            old_value: formatters[formatter](log?.old_value ?? ""),
            new_value: formatters[formatter](log?.new_value ?? ""),
            created: log.created,
            date: root.$d(new Date(log?.created ?? "now"), "long"),
            single_line: log?.single_line ?? false,
            created_by_id: log?.created_by_id,
          };
        }),
        ["created"]
      ).reverse();
    });

    return {
      events,
      displayUser,
    };
  },
};
</script>
